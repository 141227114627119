import MethodController from './method_controller'

export default class extends MethodController {

  clickNext(e){
    let regType = this.getElement("regtype")
    let isValid = true
    isValid = this.validateFields(6)
    if (!isValid) {
      e.preventDefault()
    } else {
      if (regType.value == '2') {
        let requested_available = this.array_value("00N1r00000HHVAT")
        let requested_unavailable = this.array_value("00N1r00000HHVAU")
        requested_available = this.filterRequested(requested_available)
        requested_unavailable = this.filterRequested(requested_unavailable)
        this.editInnerHtmlElement("summary-requestedavailable", this.summaryProducts(requested_available))
        this.editInnerHtmlElement("summary-requestedunavailable", this.summaryProducts(requested_unavailable))
        this.showSummary()
        this.setRouteDetials()
        this.setQualified()
        this.getElement("00N1r00000HHVAU").value.trim() == "" ? this.getElement("mystep-7").classList.add('valid') : this.getElement("mystep-7").classList.remove('valid')
        this.executeNextTab("mytab-link-7", "mystep-7", "mytab-link-6")
        this.getSelected('current_tab', '7')
      } else {
        this.executeNextTab("mytab-link-65", "mystep-65", "mytab-link-6")
        this.getSelected('current_tab', '65')
      }
    }
  }

  filterRequested(array){
    let get_array = array.split('\n')
    return get_array.filter(req => req !== '')
  }

   array_value(id){
    return this.getElement(id).value
  }
  
  summaryProducts(products){
    if (products.length !== 0) {
      let get_uniq = products.map(prod => this.groupByProductFamily(prod))
    get_uniq = [...new Set(get_uniq)]
    return get_uniq.map(header => 
      `<strong> ${header.trim()}</strong>
      ${this.groupByHeader(header, products)}
      `).join('\n')
    }
    else{
      return ''
    }
    
  }

  groupByProductFamily(product){
    let split_product = product.split(' | ')
    return split_product[1]
  }

  groupByHeader(header, products){
    let get_products_by_header =  products.map(prod => {
      if (prod.includes(header)) {
        return `${prod}`
      }
    }).join('\n').trim()

    return this.trimString(get_products_by_header)
  
  }

  trimString(string){
    let remove_large_spaces = this.filterRequested(string)
    return remove_large_spaces.join('\n')
  }

  clickPrev(e) {
    this.executeNextTab("mytab-link-5", "mystep-5", "mytab-link-6")
    this.getSelected('current_tab', '5')
  }

  clickModify(e) {
    let toModify = this.getElement("toModify")
    let toModifyText = this.getElement("00N1r00000HHVAE")
    let toModifyTextDiv = this.getElement("other-00N1r00000HHVAN")
    let summaryToModify = this.getElement("summary-tomodify")
    let summaryToModifyTextDiv = this.getElement("summary-tomodifytext-div")
    let value = e.target.value

    if (value == '1') {
      toModifyText.disabled = false
      toModifyTextDiv.classList.add('d-flex')
      toModifyTextDiv.classList.remove('d-none')
      toModify.value = 1
      summaryToModify.innerHTML = 'Yes'
      summaryToModifyTextDiv.classList.remove('d-none')
      summaryToModifyTextDiv.classList.add('d-flex')
    } else {
      toModifyText.disabled = true
      toModifyTextDiv.classList.remove('d-flex')
      toModifyTextDiv.classList.add('d-none')
      toModify.value = 0
      summaryToModify.innerHTML = 'No'
      summaryToModifyTextDiv.classList.add('d-none')
      summaryToModifyTextDiv.classList.remove('d-flex')
    }
    this.checkFastTrack()
  }

  changeModifyText(e) {
    let toModifyText = this.getElement("00N1r00000HHVAE")
    let summaryToModifyText = this.getElement("summary-tomodifytext")
    summaryToModifyText.innerHTML = toModifyText.value
  }

  clickInfo(e) {
    let summaryInfo = this.getElement("summary-info")
    let value = e.target.value

    if (value == '1') {
      summaryInfo.innerHTML = 'Yes'
    } else {
      summaryInfo.innerHTML = 'No'
    }
  }

  clickOptInProduct(e) {
    let summaryOptInProduct = this.getElement("summary-optin-product")
    let value = e.target.value

    if (value == '1') {
      summaryOptInProduct.innerHTML = 'Yes'
    } else {
      summaryOptInProduct.innerHTML = 'No'
    }
  }

  clickOptInSurvey(e) {
    let summaryOptInSurvey = this.getElement("summary-optin-survey")
    let value = e.target.value

    if (value == '1') {
      summaryOptInSurvey.innerHTML = 'Yes'
    } else {
      summaryOptInSurvey.innerHTML = 'No'
    }
  }

  clickTerms(e) {
    let summaryTerms = this.getElement("summary-terms")
    let termsELement =  this.getElement("00N1r00000HHVAY")
    let value = e.currentTarget.checked
    summaryTerms.innerHTML = value ? 'Yes' : 'No'
    termsELement.value = value ? '1' : '0'
  }
  
  executeNextTab(id, stepid, currentTab) {
    let find_tab = this.getElement(id)
    this.getElement(stepid).classList.add('active')
    find_tab.classList.remove('disabled')
    find_tab.classList.add('text-bold')
    find_tab.value = "previous"
    this.getElement(currentTab).classList.remove('text-bold')
    find_tab.click()
  }

}
