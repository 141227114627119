import MethodController from './method_controller'

export default class extends MethodController {
  clickNext(e){
    let isValid = true
    isValid = this.validateFields(65)
    if (!isValid) {
      e.preventDefault()
    } else {
      let requested_available = this.array_value("00N1r00000HHVAT")
      let requested_unavailable = this.array_value("00N1r00000HHVAU")
      requested_available = this.filterRequested(requested_available)
      requested_unavailable = this.filterRequested(requested_unavailable)
      this.editInnerHtmlElement("summary-requestedavailable", this.summaryProducts(requested_available))
      this.editInnerHtmlElement("summary-requestedunavailable", this.summaryProducts(requested_unavailable))
      this.showSummary()
      this.setRouteDetials()
      this.setQualified()
      this.getElement("00N1r00000HHVAU").value.trim() == "" ? this.getElement("mystep-7").classList.add('valid') : this.getElement("mystep-7").classList.remove('valid')
      this.executeNextTab("mytab-link-7", "mystep-7", "mytab-link-6")
      this.getSelected('current_tab', '7')
    }
  }

  filterRequested(array){
    let get_array = array.split('\n')
    return get_array.filter(req => req !== '')
  }

   array_value(id){
    return this.getElement(id).value
  }
  
  summaryProducts(products){
    if (products.length !== 0) {
      let get_uniq = products.map(prod => this.groupByProductFamily(prod))
    get_uniq = [...new Set(get_uniq)]
    return get_uniq.map(header => 
      `<strong> ${header.trim()}</strong>
      ${this.groupByHeader(header, products)}
      `).join('\n')
    }
    else{
      return ''
    }
    
  }

  groupByProductFamily(product){
    let split_product = product.split(' | ')
    return split_product[1]
  }

  groupByHeader(header, products){
    let get_products_by_header =  products.map(prod => {
      if (prod.includes(header)) {
        return `${prod}`
      }
    }).join('\n').trim()

    return this.trimString(get_products_by_header)
  
  }

  trimString(string){
    let remove_large_spaces = this.filterRequested(string)
    return remove_large_spaces.join('\n')
  }

  clickPrev(e) {
    this.executeNextTab("mytab-link-6", "mystep-6", "mytab-link-65")
    this.getSelected('current_tab', '6')
  }

  executeNextTab(id, stepid, currentTab) {
    let find_tab = this.getElement(id)
    this.getElement(stepid).classList.add('active')
    find_tab.classList.remove('disabled')
    find_tab.classList.add('text-bold')
    find_tab.value = "previous"
    this.getElement(currentTab).classList.remove('text-bold')
    find_tab.click()
  }

  changeOrganization(e) {
    let organization = this.getElement("00NRR00000AAm7F")
    let summaryorganization = this.getElement("summary-organization")
    summaryorganization.innerHTML = organization.value
  }
  changeEntity(e) {
    let entity = this.getElement("00NRR00000AAm2P")
    let summaryentity = this.getElement("summary-entity")
    summaryentity.innerHTML = entity.value
  }
  changeJurisdiction(e) {
    let jurisdiction = this.getElement("00NRR00000AAlMb")
    let summaryjurisdiction = this.getElement("summary-jurisdiction")
    summaryjurisdiction.innerHTML = jurisdiction.value
  }
  changeLicenseeAddress(e) {
    let licenseeaddress = this.getElement("00NRR000008qzVp")
    let summarylicenseeaddress = this.getElement("summary-licenseeaddress")
    summarylicenseeaddress.innerHTML = licenseeaddress.value
  }
  changeLicenseeCity(e) {
    let licenseecity = this.getElement("00NRR000008qtQP")
    let summarylicenseecity = this.getElement("summary-licenseecity")
    summarylicenseecity.innerHTML = licenseecity.value
  }
  changeLicenseeZip(e) {
    let licenseezip = this.getElement("00NRR000008qtJx")
    let summarylicenseezip = this.getElement("summary-licenseezip")
    summarylicenseezip.innerHTML = licenseezip.value
  }
  changeLicenseeCountry(e) {
    let licenseecountry = this.getElement("00NRR000008qiZx")
    let summarylicenseecountry = this.getElement("summary-licenseecountry")
    summarylicenseecountry.innerHTML = licenseecountry.value
  }
  clickSame(e) {
    let div1 = this.getElement("licensee_billing_div1")
    let div2 = this.getElement("licensee_billing_div2")
    let summarySame = this.getElement("summary-billingthesame")
    let value = e.target.value

    let licenseeaddress = this.getElement("00NRR000008qzVp")
    let licenseecity = this.getElement("00NRR000008qtQP")
    let licenseezip = this.getElement("00NRR000008qtJx")
    let licenseecountry = this.getElement("00NRR000008qiZx")

    let summarybillingaddress = this.getElement("summary-billingaddress")
    let summarybillingcity = this.getElement("summary-billingcity")
    let summarybillingzip = this.getElement("summary-billingzip")
    let summarybillingcountry = this.getElement("summary-billingcountry")

    if (value == '0') {
      div1.classList.remove('d-none')
      div2.classList.remove('d-none')
      div1.classList.add('d-flex')
      div2.classList.add('d-flex')
      summarySame.innerHTML = 'No'

      summarybillingaddress.innerHTML = ""
      summarybillingcity.innerHTML = ""
      summarybillingzip.innerHTML = ""
      summarybillingcountry.innerHTML = ""
    } else {
      div1.classList.add('d-none')
      div2.classList.add('d-none')
      div1.classList.remove('d-flex')
      div2.classList.remove('d-flex')
      summarySame.innerHTML = 'Yes'

      summarybillingaddress.innerHTML = licenseeaddress.value
      summarybillingcity.innerHTML = licenseecity.value
      summarybillingzip.innerHTML = licenseezip.value
      summarybillingcountry.innerHTML = licenseecountry.value
    }
  }
  changeBillingAddress(e) {
    let billingaddress = this.getElement("00NRR000008qxXS")
    let summarybillingaddress = this.getElement("summary-billingaddress")
    summarybillingaddress.innerHTML = billingaddress.value
  }
  changeBillingCity(e) {
    let billingcity = this.getElement("00NRR000008qzfV")
    let summarybillingcity = this.getElement("summary-billingcity")
    summarybillingcity.innerHTML = billingcity.value
  }
  changeBillingZip(e) {
    let billingzip = this.getElement("00NRR000008qzcH")
    let summarybillingzip = this.getElement("summary-billingzip")
    summarybillingzip.innerHTML = billingzip.value
  }
  changeBillingCountry(e) {
    let billingcountry = this.getElement("00NRR000008qzpB")
    let summarybillingcountry = this.getElement("summary-billingcountry")
    summarybillingcountry.innerHTML = billingcountry.value
  }
  changeContactPerson(e) {
    let contactperson = this.getElement("00NRR000008r0DN")
    let summarycontactperson = this.getElement("summary-contactperson")
    summarycontactperson.innerHTML = contactperson.value
  }
  changeEmailContact(e) {
    let emailcontactperson = this.getElement("00NRR000008r3NR")
    let summaryemailcontactperson = this.getElement("summary-emailcontactperson")
    summaryemailcontactperson.innerHTML = emailcontactperson.value
  }
  changeVat(e) {
    let companyvat = this.getElement("00NRR000008qmnA")
    let summarycompanyvat = this.getElement("summary-companyvat")
    summarycompanyvat.innerHTML = companyvat.value
  }
  changePO(e) {
    let ponumber = this.getElement("00NRR000008r3kj")
    let summaryponumber = this.getElement("summary-ponumber")
    summaryponumber.innerHTML = ponumber.value
  }

  addVendor(e) {
    let value = e.target.value
    let summaryVendorInvolved = this.getElement("summary-vendors-involved")
    let summaryVendorDiv = this.getElement("summary-vendors-div")
    let addDivVendor = this.getElement("addVendorParent")
    if (value == '1') {
      addDivVendor.classList.remove('d-none')
      summaryVendorInvolved.innerHTML = 'Yes'
      summaryVendorDiv.classList.remove('d-none')
      summaryVendorDiv.classList.add('d-flex')
    } else {
      addDivVendor.classList.add('d-none')
      summaryVendorInvolved.innerHTML = 'No'
      summaryVendorDiv.classList.add('d-none')
      summaryVendorDiv.classList.remove('d-flex')
    }
  }

  addVendorDiv(e) {
    let get_id = e.currentTarget.id
    let result = get_id.split('-')
    let count = parseInt(result[1]) + 1
    let div = this.getElement("addVendor")

    let closestVendorName = this.getElement(get_id).closest(".addVendorParent").querySelector(".addVendorEmail").value.trim()
    let closestVendorEmail = this.getElement(get_id).closest(".addVendorParent").querySelector(".addVendorPhone").value.trim()
    if (this.emailIsValid(closestVendorEmail) && closestVendorName != "") {
      let htmlString = '<div class="addVendorParent" id="addedDivVendor' + count + '"  data-number="' + count + '">'
      htmlString += '<div class="row vendor-content col-sm-12 col-md-12 ml-3">'
      htmlString += '<div class="form-group pl-0 pr-1 col-sm-5 col-md-5">'
      htmlString += '<input type="text" id="addVendorName-' + count + '" class="form-control mt-1 addVendorEmail" name="vendorName[]">'
      htmlString += '</div>'
      htmlString += '<div class="form-group pl-1 pr-0 col-sm-5 col-md-5">'
      htmlString += '<input type="text" id="addVendorEmail-' + count + '" class="form-control mt-1 addVendorPhone" name="vendorEmail[]">'
      htmlString += '</div>'
      htmlString += '<div class="col-sm-2 col-md-2 px-0">'
      htmlString += '<span class="removeVendor" id="removeVendor-' + count + '" data-action="click->second#removeVendorDiv"><svg xmlns="http://www.w3.org/2000/svg" class="text-danger" height="20" fill="none" viewBox="-2.5 -2.5 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></span>'
      htmlString += '<span class="addVendor mr-1" id="plusVendor-' + count + '" data-action="click->second#addVendorDiv"><svg xmlns="http://www.w3.org/2000/svg" class="eqText-success" height="20" fill="none" viewBox="-2.5 -2.5 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></span>'
      htmlString += '</div>'
      htmlString += '</div>'
      htmlString += '</div>'
      div.insertAdjacentHTML('beforeend', htmlString);
      const remove_btn = this.getElement('removeVendor-' + parseInt(result[1]) + '')
      if (remove_btn) {
        this.hideElement(remove_btn.id)
      }
      this.hideElement(get_id)
    } else {
      alert('Please Input Correct Email/Vendor or CRO')
    }
  }

  removeVendorDiv(e) {
    this.removeDivVendor(e, 'removeVendor-', 'plusVendor-', 'addedDivVendor')
  }

  removeDivVendor(e, removeId, addId, addDivId) {
    let get_id = e.currentTarget.id
    const get_count = get_id.split('-')
    let result = parseInt(get_count[1]) - 1
    const remove_div = this.getElement('' + addDivId + get_count[1] + '')
    const remove_btn = this.getElement('' + removeId + '' + result + '')
    if (remove_btn) {
      this.showElement(remove_btn.id)
    }
    this.showElement('' + addId + result + '')
    remove_div.remove()
  }

  updateVendor() {
    let textVendor = this.getElement("00NQu000002dihe")
    let sumTextVendors = this.getElement("summary-vendors")
    let textVendorValue = ''
    let divs = document.querySelectorAll('.addVendorParent')
    for (let i = 0; i < divs.length; i++) {
      let closestVendorEmail = this.getElement("addVendorEmail-" + i).value.trim()
      let closestVendorName = this.getElement("addVendorName-" + i).value.trim()
      if (this.emailIsValid(closestVendorEmail) && closestVendorName != "") {
        textVendorValue += `${closestVendorName} <${closestVendorEmail}>\n`
      }
    }
    textVendor.value = textVendorValue.trim()
    sumTextVendors.innerText = textVendorValue.trim()
  }
}